import { useMutation } from "react-query";
import axios from "axios";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import {
  orderServiceCountQuery,
  orderServiceCreate,
  orderServiceCustomRoute,
  orderServiceFetchCsv,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdate,
} from "./orderService";

import {
  HRS_ASSAY,
  COVID_ASSAY_PROD,
  MPX_ASSAY_PROD,
  NORO_ASSAY_PROD,
  RESP_ASSAY_PROD,
  DDPCR_METHOD_TYPE,
  QPCR_METHOD_TYPE,
  COVID_VARIANTS_ASSAY,
} from "configs/constants";

/*********************************************************
 * STATIC OPTIONS
 *********************************************************/

export const PCR_UPLOAD_ASSAY_OPTIONS = [
  { label: "SARS-CoV-2", value: COVID_ASSAY_PROD },
  { label: "Monkeypox (Prod)", value: MPX_ASSAY_PROD },
  { label: "Norovirus (Prod)", value: NORO_ASSAY_PROD },
];

export const DDPCR_UPLOAD_ASSAY_OPTIONS = [
  { label: "Respiratory (Prod)", value: RESP_ASSAY_PROD },
];

export const PCR_METHOD_TYPE_OPTIONS = [
  { label: "Quantitative (qPCR)", value: QPCR_METHOD_TYPE },
  { label: "Digital Droplet (ddPCR)", value: DDPCR_METHOD_TYPE },
];

export const LCMS_UPLOAD_ASSAY_OPTIONS = [{ label: "HRS", value: HRS_ASSAY }];
export const VARSEQ_UPLOAD_ASSAY_OPTIONS = [
  { label: "Covid Variants (Prod)", value: COVID_VARIANTS_ASSAY },
];

export const PLATE_NAME_OPTIONS = [
  { value: "targets", label: "Targets" },
  { value: "controls", label: "Controls" },
  { value: "targets,controls", label: "Targets, Controls" },
];

/*********************************************************
 *  HOOKS FOR MODELS:
 *  - UPLOAD_SCHEMA_VERSIONS
 *  - LAB_PROTOCOL_VERSIONS
 *  - STANDARD_CURVE_VERSIONS
 *********************************************************/

export const usePcrUploadSchemaVersionList = orderServiceListQuery({
  model: "upload-schema-versions",
  defaults: {
    _where: {
      upload_type: ["qpcr_xlsx", "ddpcr_csv"],
      active: true,
    },
  },
});

export const useLcmsUploadSchemaVersionList = orderServiceListQuery({
  model: "upload-schema-versions",
  defaults: {
    _where: {
      upload_type: "tracefinder_csv",
      active: true,
    },
  },
});

export const useVarseqUploadSchemaVersionList = orderServiceListQuery({
  model: "upload-schema-versions",
  defaults: {
    _where: {
      upload_type: "samplesheet_csv",
    },
  },
});

export const useStandardCurveVersionList = orderServiceListQuery({
  model: "standard-curve-versions",
  default: {
    _where: {
      active: true,
    },
  },
});

export const usePcrLabProtocolVersionList = orderServiceListQuery({
  model: "lab-protocol-versions",
  defaults: {
    _where: {
      lab_method_contains: ["qpcr", "ddpcr"],
      active: true,
    },
  },
});

export const useLcmsLabProtocolVersionList = orderServiceListQuery({
  model: "lab-protocol-versions",
  defaults: {
    _where: {
      lab_method_contains: "lcms",
      active: true,
    },
  },
});

export const useVarseqLabProtocolVersionList = orderServiceListQuery({
  model: "lab-protocol-versions",
  defaults: {
    _where: {
      lab_method_contains: "varseq",
      active: true,
    },
  },
});

export const useLcmsAnalysisProtocolVersionList = orderServiceListQuery({
  model: "analysis-protocol-versions",
  defaults: {
    _where: {
      version_name_contains: "hrs",
      active: true,
    },
  },
});

/*********************************************************
 * PCR UPLOAD MODEL HOOKS
 *********************************************************/

const pcrModel = "qpcr-uploads"; // TODO: Not going to rename this so pipelines doesn't have to rename downstream (Per request of Computer Kyle)

export const usePcrUpload = orderServiceFindOneQuery({ model: pcrModel });
export const usePcrUploadList = orderServiceListQuery({ model: pcrModel });
export const usePcrUploadCount = orderServiceCountQuery({ model: pcrModel });

export const useBulkCreatePcrUpload = () =>
  useMutation(async (formValue) =>
    Promise.allSettled(getFileUploadRequests(pcrModel, formValue))
  );

export const useReuploadPcrUploadFile = () =>
  useMutation(({ upload, file }) =>
    reuploadFileUpload(pcrModel, { fileUpload: upload, file })
  );

export const fetchPcrUploadCsv = ({ _where, _sort }) =>
  orderServiceFetchCsv({ model: pcrModel, _where, _sort });

/*********************************************************
 * LCMS UPLOAD MODEL HOOKS
 *********************************************************/

const lcmsModel = "lcms-uploads";

export const useLcmsUpload = orderServiceFindOneQuery({ model: lcmsModel });
export const useLcmsUploadList = orderServiceListQuery({ model: lcmsModel });
export const useLcmsUploadCount = orderServiceCountQuery({ model: lcmsModel });

export const useBulkCreateLcmsUpload = () =>
  useMutation(async (formValue) =>
    Promise.allSettled(getFileUploadRequests(lcmsModel, formValue))
  );

export const useReuploadLcmsUploadFile = () =>
  useMutation(({ lcmsUpload, file }) =>
    reuploadFileUpload(lcmsModel, { fileUpload: lcmsUpload, file })
  );

export const fetchLcmsUploadCsv = ({ _where, _sort }) =>
  orderServiceFetchCsv({ model: lcmsModel, _where, _sort });

/*********************************************************
 * Varseq UPLOAD MODEL HOOKS
 *********************************************************/

const varseqModel = "varseq-uploads";

export const useVarseqUpload = orderServiceFindOneQuery({ model: varseqModel });
export const useVarseqUploadList = orderServiceListQuery({
  model: varseqModel,
});
export const useVarseqUploadCount = orderServiceCountQuery({
  model: varseqModel,
});

export const useBulkCreateVarseqUpload = () =>
  useMutation(async (formValue) =>
    Promise.allSettled(getFileUploadRequests(varseqModel, formValue))
  );

export const fetchVarseqUploadCsv = ({ _where, _sort }) =>
  orderServiceFetchCsv({ model: varseqModel, _where, _sort });

/*********************************************************
 * FILE UPLOAD REQUEST GENERATORS
 *********************************************************/

/**
 * Maps file upload submission form to array of file upload create requests
 * - merges batch and plate level metadata into each individual upload
 * - parses out upload-less plates and batches
 * @param {string} model : model for upload file "pcr-uploads" or "lcms-uploads"
 * @param {*} formValue pcr/lcms upload submission form value
 * @returns {Array<Promise<{ success: boolean }>>} array of pcr upload create requests
 */
const getFileUploadRequests = (model, formValue) => {
  const _flattenRerunSamples = (formBatch) => {
    let allReruns = [];
    if (!_.isNil(formBatch.rerun_samples)) {
      allReruns = allReruns.concat(formBatch.rerun_samples);
    }
    if (!_.isNil(formBatch.rerun_batches)) {
      for (const rerunBatch of formBatch.rerun_batches) {
        const rerunBatchReason = rerunBatch["rerun_batch_notes"];
        // Check if using newline delimiter as copy-paste from GSheet. Otherwise accept comma-delimited
        const rerunBatchDelimeter =
          rerunBatch["rerun_batch_sample_ids"].indexOf("\n") !== -1
            ? "\n"
            : ",";
        const rerunBatchSamples = rerunBatch["rerun_batch_sample_ids"].split(
          rerunBatchDelimeter
        );

        for (const rerunBatchSampleId of rerunBatchSamples) {
          const newRerun = {
            sample_id: rerunBatchSampleId,
            rerun_notes: rerunBatchReason,
          };
          allReruns.push(newRerun);
        }
      }
    }

    return allReruns;
  };

  // Convert the relevant date field to ISO for
  let isoDate =
    model === pcrModel
      ? formValue.date_of_qpcr?.toISOString()
      : model === lcmsModel
      ? formValue.lcms_reviewed_at?.toISOString()
      : model === varseqModel
      ? formValue.date_of_seq?.toISOString()
      : null;

  // Strip out the form data that is handled below
  let submissionLevelMetadata = {
    ..._.omit(formValue, [
      "batches",
      "date_of_qpcr",
      "date_of_seq",
      "uploads",
      "lcms_reviewed_at",
    ]),
    ...(model === pcrModel && { date_of_qpcr: isoDate }),
    ...(model === lcmsModel && { lcms_reviewed_at: isoDate }),
    ...(model === varseqModel && { date_of_seq: isoDate }),
  };

  // For a given upload in the form, send the upload request
  const createUploadRequest = (
    model,
    submissionLevelMetadata,
    uploadFile,
    batchLevelMetadata = {}
  ) => {
    // Skip uploads with no file
    if (_.isNil(uploadFile.uploaded_file)) return null;

    const fileUpload = {
      ...submissionLevelMetadata,
      ...batchLevelMetadata,
      ...uploadFile,
    };

    const fileUploadMeta = _.omit(fileUpload, "uploaded_file");
    const file = fileUpload.uploaded_file;

    return uploadFileToS3(model, file)
      .then((fileData) => ({ ...fileData, ...fileUploadMeta }))
      .then((data) => createFileUpload(model, data))
      .then((res) => ({ ...res, success: true }))
      .catch((error) => ({
        ...fileUpload,
        success: false,
        error,
      }));
  };

  // map all upload requests into a single list, taking potential batching into account
  let mappedRequests = [];
  if (!_.isUndefined(formValue.batches)) {
    mappedRequests = formValue.batches.map((batch) => {
      // no need to iterate batch's uploads if none of them have files
      if (!_.some(batch?.uploads, "uploaded_file")) return null;

      // custom field handling. reruns require flattening, and uploads are a special case
      const batchLevelMetadata = _.omit(batch, [
        "uploads",
        "rerun_samples",
        "rerun_batches",
      ]);
      if (!_.isEmpty(batch.rerun_samples)) {
        batchLevelMetadata["rerun_samples"] = JSON.stringify(
          _flattenRerunSamples(batch)
        );
      }

      return _.map(batch?.uploads, (upload) => {
        return createUploadRequest(
          model,
          submissionLevelMetadata,
          upload,
          batchLevelMetadata
        );
      });
    });
  } else {
    mappedRequests = _.map(formValue.uploads, (upload) => {
      return createUploadRequest(model, submissionLevelMetadata, upload, {});
    });
  }

  // flattens batches into array of upload payloads and removes upload-less batches
  return _.compact(_.flatten(mappedRequests));
};

/**
 * Adds file upload metadata to order service, for qpcr-uploads and lcms-uploads
 * - on error, rolls back S3 file creation to prevent lost files
 * @param {string} model : model for upload file "qpcr-uploads" or "lcms-uploads"
 * @param {*} data file upload model data
 * @returns {object} file upload model data
 */
const createFileUpload = (model, data) =>
  orderServiceCreate({ model, data }).catch(async (e) => {
    console.warn(`rolling back S3 upload`);
    return await deleteFileFromS3(pcrModel, data).then(() => {
      // propagate error upward
      throw new Error(`Failed to create ${model} Metadata: ${e}`);
    });
  });

/**
 * Replaces file for provided uploaded file
 * - uploads new file to S3
 * - if successful, updates file upload meta model and removes previous file
 * @param {string} model : model for upload file "pcr-uploads" or "lcms-uploads"
 * @param {{ fileUpload: PcrUpload|LcmsUpload; file: File }}
 * @returns {{ file_s3_bucket: string; file_s3_key: string; file_name: string }}
 * newly uploaded file data
 */
const reuploadFileUpload = (
  model,
  { fileUpload: existingFileUpload, file }
) => {
  return uploadFileToS3(model, file)
    .then((fileData) => ({ id: existingFileUpload?.id, ...fileData }))
    .then((updatePayload) =>
      updateFileUpload(model, updatePayload, existingFileUpload)
    );
};

/**
 * Updates file upload metadata in order service
 * - on error, rolls back S3 file creation to prevent lost files
 * @param {string} model : model for upload file "pcr-uploads" or "lcms-uploads"
 * @param {*} payload file upload model data
 * @returns {Promise<FileUploadData>} fileUpload model data
 * @throws update error to root catch block
 */
const updateFileUpload = (model, payload) =>
  orderServiceUpdate({ model, data: payload })
    .then(() => payload)
    .catch(async () => {
      await deleteFileFromS3(model, payload);
      // propagate error upward
      throw new Error(
        `Failed to update uploaded file for "${payload?.file_name}"`
      );
    });

/*********************************************************
 * S3 HANDLERS
 *********************************************************/

/**
 * Get signedUrl from S3 to download uploaded file
 * @param {string} model : model for upload file "pcr-uploads" or "lcms-uploads"
 * @param {number} id : ID of pcr-upload
 * @returns {Promise<string>} : signedUrl for permissioned download from S3
 */
export const getSignedS3DownloadUrl = (model, id) =>
  orderServiceCustomRoute({
    model,
    path: `download-url/${id}`,
  });

/**
 * Get signedUrl from S3 to send uploaded file
 * @param {string} model : model for upload file "pcr-uploads" or "lcms-uploads"
 * @returns {Promise<{ fileKey: string; signedUrl: string }>} : fileUrl for adding to model, signedUrl for permissioned upload to S3
 * Pass in unique request_id to params to ensure that Safari doesn't ignore it as a duplicate request
 */
export const getSignedS3UploadURL = (model, file) => {
  const fileType = file.type.split("/")[1];
  return orderServiceCustomRoute({
    model,
    method: "GET",
    path: `upload-url`,
    params: {
      request_id: uuid(),
      file_type: fileType,
    },
  });
};
/**
 * Upload file to s3 using signed Url
 * @param {string} signedS3Url
 * @param {File} file
 * @returns {Promise<AxiosResponse<null>>}
 */
const uploadToSignedS3Url = async (signedS3Url, file) =>
  axios({
    method: "PUT",
    url: signedS3Url,
    headers: { "Content-Type": file.type },
    data: file,
  });

/**
 * Generates signed S3 url and uploads to S3
 * @param {string} model : model for upload file "pcr-uploads" or "lcms-uploads"
 * @param {File} file
 * @returns {object | null} : file-related properties on pcr upload model if successfully uploaded
 *
 * example return value:
 * ```
 * {
 *  file_s3_key: 'pcr-uploads/c72abc33-2893-49ee-a01e-9fab036d1c3d.xlsx,
 *  file_s3_bucket: 'biobot-pipelines-pcr-input-dev',
 *  file_name: '2022-10-21_Plate18_MPXV_E9L-NVAR_PCR_Cy0.xlsx',
 * }
 * ```
 */
const uploadFileToS3 = async (model, file) => {
  const { fileKey, bucket, signedUrl } = await getSignedS3UploadURL(
    model,
    file
  );
  const res = await uploadToSignedS3Url(signedUrl, file);
  if (res.status === 200) {
    return {
      file_s3_key: fileKey,
      file_s3_bucket: bucket,
      file_name: file.name,
    };
  } else {
    throw new Error("Failed to send upload file to S3");
  }
};

/**
 * Delete uploaded file from S3 by key and bucket
 * @param {string} model : model for upload file "pcr-uploads" or "lcms-uploads"
 * @param {{ file_s3_key: string; file_s3_bucket: string }} fileUpload
 */
const deleteFileFromS3 = async (model, fileUpload) => {
  await orderServiceCustomRoute({
    model,
    path: "uploaded-file",
    method: "DELETE",
    params: _.pick(fileUpload, ["file_s3_key", "file_s3_bucket"]),
  });
};
